<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="setting"
          :breadcrumb="[
             {
                label: 'Dashboard',
            },
            {
              label: 'Masters',
            },
            { label: 'Setting' }

        
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>

        <!-- <div class="row">
                    <div class="col-xl-3 col-md-3 col-12" style="padding:5px 5px 5px 5px;">
                        <div class="card" style="height:50%"> 
                            <div class="card-body">hi</div>
                        </div>
                        <div class="card" style="height:50%">
                            <div class="card-body">hi</div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-md-9 col-12" style="padding:5px 5px 5px 5px;">
                        <div class="card" style="height:100%">
                            <div class="card-body">hi</div>
                        </div>
                    </div>
                  </div> -->

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-3 col-md-3 col-12" style="padding: 5px 5px 5px 5px">
            <div
              class="card custom-card "
              style="margin-left: 3px"
            >
              <div class="card-body font text-center">
                <h4><strong class="text">Software Logo</strong></h4>
                <hr />
                <div class="row">
                  <div class="col p-1">
                    <img style="width: 100%"   :src="proUpdatelogo" alt="" />
                  </div>
                </div>
                <hr />
                <div class="row mt-1">
                  <div class="col">
                    <label for="profileLogo">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                        alt=""

                      />
                      &nbsp; <strong>Choose Logo</strong></label
                    >
                    <input
                      type="file"
                      style="position: absolute; top: -150000px"
                    id="profileLogo"
                      @change="uploadLogo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card custom-card "
              style="margin-left: 3px"
            >
              <div class="card-body font text-center">
                <h4><strong class="text">Software Favicon</strong></h4>
                <hr />
                <div class="row">
                  <div class="col p-1">
                    <img style="width: 100%"   :src="proUpdateFav" alt="" />
                  </div>
                </div>
                <hr />
                <div class="row mt-1">
                  <div class="col">
                    <!-- <label for="formFile
                    
                    ">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                        alt=""

                      />
                      &nbsp; <strong>Choose Favicon</strong></label
                    > -->
                    <label for="formFile" ><img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                        alt=""

                      /> <strong>Choose Favicon</strong></label>
                    <!-- <input
                      type="file"
                      style="position: absolute; top: -150000px"
                    id="profileFavicone"
                      @change="uploadFav"
                    /> -->
                  
  <input    style="position: absolute; top: -150000px"  @change="uploadFav" class="form-control" type="file" id="formFile">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-md-9 col-12" style="padding: 5px 5px 5px 5px">
            <div
              class="card custom-card"
              style=" height: 100%"
            >
              <div class="card-body">
                <table class="table table-bordered">
                  <tr
                    style="
                      background-color: #00364f;
                      padding: 5px 5px 5px 5px;
                      color: white;
                    "
                  >
                    <th style="width: 30%" class="text-start">
                      &nbsp;&nbsp;Company Profile
                    </th>
                    <th style="width: 65%" class="text-end"></th>
                    <th style="width: 5%" class="text-end">
                      <font-awesome-icon icon="edit" />
                    </th>
                  </tr>

                  <tbody>
                    <tr>
                      <th scope="row"><strong class="text">Website</strong></th>

                      <td>
                        <div v-if="this.form.field == 'website'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.website }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'website'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('website', superAdmin.website)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row"><strong class="text">Company Name</strong></th>

                      <td>
                        <div v-if="this.form.field == 'name'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.name }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'name'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('name', superAdmin.name)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row"><strong class="text">Short Name</strong></th>
                      <td>
                        <div v-if="this.form.field == 'shortName'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.shortName }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'shortName'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('shortName', superAdmin.shortName)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row"><strong class="text">About</strong></th>
                      <td>
                        <div v-if="this.form.field == 'about'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.about }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'about'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('about', superAdmin.about)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row"><strong class="text">Email</strong></th>
                      <td>
                        <div v-if="this.form.field == 'email'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.email }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'email'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('email', superAdmin.email)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Contact Name</strong></th>
                      <td>
                        <div v-if="this.form.field == 'contactName'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.contactName }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'contactName'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('contactName', superAdmin.contactName)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Address</strong></th>
                      <td>
                        <div v-if="this.form.field == 'address'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.address }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'address'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('address', superAdmin.address)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row"><strong class="text">State</strong></th>
                      <td>
                        <div v-if="this.form.field == 'state'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.state }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'state'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('state', superAdmin.state)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row"><strong class="text">City</strong></th>
                      <td>
                        <div v-if="this.form.field == 'city'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.city }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'city'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('city', superAdmin.city)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row"><strong class="text">Pin</strong></th>
                      <td>
                        <div v-if="this.form.field == 'pin'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                            @change="lenthheker($event, 'pinCode')"
                          />
                          <span style="color: #f21000" id="pinCode"></span>
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.pin }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'pin'">
                          <button
                          v-if="pinCode == 'false'"
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                          <button
                          v-else
                         
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('pin', superAdmin.pin)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Currency Symbol</strong></th>
                      <td>
                        <div v-if="this.form.field == 'currencySymbol'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.currencySymbol
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'currencySymbol'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField('currencySymbol', superAdmin.currencySymbol)
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">GSTIN</strong></th>
                      <td>
                        <div v-if="this.form.field == 'gstin'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                            @change="lenthheker($event, 'gstinVal')"
                          />
                          <span style="color: #f21000" id="gstinVal"></span>
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.gstin }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'gstin'">
                          <button 
                          v-if="gstinVal == 'false'"
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                          <button
                           v-else
                           
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('gstin', superAdmin.gstin)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">TAN</strong></th>
                      <td>
                        <div v-if="this.form.field == 'tds'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.tds }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'tds'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('tds', superAdmin.tds)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Account Name</strong></th>
                      <td>
                        <div v-if="this.form.field == 'accountName'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.accountName }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'accountName'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('accountName', superAdmin.accountName)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Ifsc</strong></th>
                      <td>
                        <div v-if="this.form.field == 'ifsc'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.ifsc }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'ifsc'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('ifsc', superAdmin.ifsc)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text"> Swift Code</strong></th>
                      <td>
                        <div v-if="this.form.field == 'swiftCode'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.swiftCode }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'swiftCode'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('swiftCode', superAdmin.swiftCode)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text"> Facebook</strong></th>
                      <td>
                        <div v-if="this.form.field == 'facebook'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.facebook }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'facebook'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('facebook', superAdmin.facebook)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text"> Twitter</strong></th>
                      <td>
                        <div v-if="this.form.field == 'twitter'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.twitter }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'twitter'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('twitter', superAdmin.twitter)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Pntrest</strong></th>
                      <td>
                        <div v-if="this.form.field == 'pintrest'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.pintrest }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'pintrest'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('pintrest', superAdmin.pintrest)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text"> Linkedin</strong></th>
                      <td>
                        <div v-if="this.form.field == 'linkedin'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.linkedin }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'linkedin'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('linkedin', superAdmin.linkedin)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Instagram</strong></th>
                      <td>
                        <div v-if="this.form.field == 'instagram'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.instagram }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'instagram'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('instagram', superAdmin.instagram)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text"> Reddit</strong></th>
                      <td>
                        <div v-if="this.form.field == 'reddit'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.reddit }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'reddit'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('reddit', superAdmin.reddit)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Youtube</strong></th>
                      <td>
                        <div v-if="this.form.field == 'youtube'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.youtube }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'youtube'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('youtube', superAdmin.youtube)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row"><strong class="text">Google Map</strong></th>
                      <td>
                        <div v-if="this.form.field == 'googleMap'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.googleMap }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'googleMap'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('googleMap', superAdmin.googleMap)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong class="text">Super Admin Prefix</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'superAdminPrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.superAdminPrefix
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'superAdminPrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField('superAdminPrefix', superAdmin.superAdminPrefix)
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong class="text"> Super Admin Last Series</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'superAdminLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.superAdminLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'superAdminLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'superAdminLastSeries',
                                superAdmin.superAdminLastSeries
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong class="text">Professional Prefix</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'professionalPrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.professionalPrefix
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'professionalPrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'professionalPrefix',
                                superAdmin.professionalPrefix
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong class="text">Professional Last Series</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'professionalLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.professionalLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'professionalLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'professionalLastSeries',
                                superAdmin.professionalLastSeries
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Admin Prefix</strong></th>
                      <td>
                        <div v-if="this.form.field == 'adminPrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.adminPrefix }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'adminPrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('adminPrefix', superAdmin.adminPrefix)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Admin Last Series</strong></th>
                      <td>
                        <div v-if="this.form.field == 'adminLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.adminLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'adminLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField('adminLastSeries', superAdmin.adminLastSeries)
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Verifier Prefix</strong></th>
                      <td>
                        <div v-if="this.form.field == 'verifierPrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.verifierPrefix
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'verifierPrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField('verifierPrefix', superAdmin.verifierPrefix)
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong class="text"> Verifier Last Series</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'verifierLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.verifierLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'verifierLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'verifierLastSeries',
                                superAdmin.verifierLastSeries
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong class="text">Master Distributer Prefix</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'masterDistributerPrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.masterDistributerPrefix
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'masterDistributerPrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'masterDistributerPrefix',
                                superAdmin.masterDistributerPrefix
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong class="text"> Master Distributer Last Series</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'masterDistributerLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.masterDistributerLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'masterDistributerLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'masterDistributerLastSeries',
                                superAdmin.masterDistributerLastSeries
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong class="text"> Distributer Prefix</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'distributerPrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.distributerPrefix
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'distributerPrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField('distributerPrefix', superAdmin.distributerPrefix)
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong class="text"> Distributer Last Series</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'distributerLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.distributerLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'distributerLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'distributerLastSeries',
                                superAdmin.distributerLastSeries
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Retailer Prefix</strong></th>
                      <td>
                        <div v-if="this.form.field == 'retailerPrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.retailerPrefix
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'retailerPrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField('retailerPrefix', superAdmin.retailerPrefix)
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong class="text"> Retailer Last Series</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'retailerLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.retailerLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'retailerLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'retailerLastSeries',
                                superAdmin.retailerLastSeries
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text"> Client Prefix</strong></th>
                      <td>
                        <div v-if="this.form.field == 'clientPrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.clientPrefix }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'clientPrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('clientPrefix', superAdmin.clientPrefix)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong class="text"> Client Last Series</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'clientLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.clientLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'clientLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField('clientLastSeries', superAdmin.clientLastSeries)
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong class="text">Client Service Prefix</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'clientServicePrefix'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.clientServicePrefix
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'clientServicePrefix'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'clientServicePrefix',
                                superAdmin.clientServicePrefix
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong class="text"> Client Service Last Series</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'clientServiceLastSeries'">
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.clientServiceLastSeries
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'clientServiceLastSeries'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'clientServiceLastSeries',
                                superAdmin.clientServiceLastSerie
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text"> SMS Url</strong></th>
                      <td>
                        <div v-if="this.form.field == 'smsUrl'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.smsUrl }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'smsUrl'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('smsUrl', superAdmin.smsUrl)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">SMS Test</strong></th>
                      <td>
                        <div v-if="this.form.field == 'smsTest'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.smsTest }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'smsTest'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('smsTest', superAdmin.smsTest)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">SMS User</strong></th>
                      <td>
                        <div v-if="this.form.field == 'smsUser'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.smsUser }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'smsUser'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('smsUser', superAdmin.smsUser)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">SMS Password</strong></th>
                      <td>
                        <div v-if="this.form.field == 'smsPassword'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.smsPassword }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'smsPassword'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('smsPassword', superAdmin.smsPassword)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong class="text">SMS Template Company Name</strong>
                      </th>
                      <td>
                        <div v-if="this.form.field == 'smsTemplateCompanyName'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{
                            superAdmin.smsTemplateCompanyName
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'smsTemplateCompanyName'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="
                              editField(
                                'smsTemplateCompanyName',
                                superAdmin.smsTemplateCompanyName
                              )
                            "
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Timezone</strong></th>
                      <td>
                        <div v-if="this.form.field == 'timezone'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.timezone }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'timezone'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('timezone', superAdmin.timezone)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">Date Formate</strong></th>
                      <td>
                        <div v-if="this.form.field == 'dateFormate'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.dateFormate }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'dateFormate'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('dateFormate', superAdmin.dateFormate)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text">User ID</strong></th>
                      <td>
                        <div v-if="this.form.field == 'userId'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.userId }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'userId'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('userId', superAdmin.userId)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><strong class="text"> IpAddress</strong></th>
                      <td>
                        <div v-if="this.form.field == 'ipAddress'">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.value"
                            style="height: 28px"
                          />
                        </div>
                        <div v-else>
                          <span style="color: black">{{ superAdmin.ipAddress }}</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="this.form.field == 'ipAddress'">
                          <button
                            @click="saveData"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f; padding: 5px 5px 5px 5px"
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            @click="editField('ipAddress', superAdmin.ipAddress)"
                            class="btn btn-sm text-white"
                            style="background-color: green; padding: 5px 5px 5px 5px"
                          >
                            <font-awesome-icon icon="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery
export default {
  name: "Setting",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      updateProfileForm:{
              logo:''
      },
      proUpdatelogo: this.$store.state.placeholderImg,
      proUpdateFav: this.$store.state.placeholderImg,
      superAdmin: {},
      loading: false,
      allstates: [],
      extensionkycProfileLogo: "",
      extensionkycProfileFav: "",
      edit: "false",
      form: {
        field: "",
        value: "",
        rules: "required",
      },
      pinCode: "false",
      gstinVal: "false",
    };
  },
  methods: {

    
uploadLogo(e) {

      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
     
        
        this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>Logo Size Too Big 2MB</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#profileLogo").value;
        this.extensionkycProfileLogo = fileName.split(".").pop();

        if (
          this.extensionkycProfileLogo == "png" ||
    this.extensionkycProfileLogo == "PNG" ||
    this.extensionkycProfileLogo == "JPEG" ||
    this.extensionkycProfileLogo == "jpeg" ||
    this.extensionkycProfileLogo == "JPG" ||
    this.extensionkycProfileLogo == "jpg" 

        ) {
          this.proUpdatelogo = URL.createObjectURL(e.target.files["0"]);
          this.updateProfileForm.logo = e.target.files["0"];
          this.changheLogoSoftware('logo')
        } else {


          this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>File type must JPG ,JPEG, PNG</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
        }
      }
    },

    uploadFav(e) {

this.msgImgError = "";
if (e.target.files["0"].size > 2 * (1024 * 1024)) {
  e.preventDefault();

  
  this.$swal.fire({
            position: "top-center",
            icon: "error",
            title: "<small>Fav Size Too Big 2MB</small>",
            showConfirmButton: true,
            confirmButtonColor: "#00364f",
          });
  return false;
} else {
  var fileName;
  fileName = document.querySelector("#formFile").value;
  this.extensionkycProfileFav = fileName.split(".").pop();

  if (
 
    this.extensionkycProfileFav == "png" ||
    this.extensionkycProfileFav == "PNG" ||
    this.extensionkycProfileFav == "JPEG" ||
    this.extensionkycProfileFav == "jpeg" ||
    this.extensionkycProfileFav == "JPG" ||
    this.extensionkycProfileFav == "jpg" ||
    this.extensionkycProfileFav == "ico" ||
    this.extensionkycProfileFav == "ICO" 
  ) {
    this.proUpdateFav = URL.createObjectURL(e.target.files["0"]);
    this.updateProfileForm.favicon = e.target.files["0"];
    this.changheLogoSoftware('favicon')
  } else {


    this.$swal.fire({
            position: "top-center",
            icon: "error",
            title: "<small>File type must ICO</small>",
            showConfirmButton: true,
            confirmButtonColor: "#00364f",
          });
  }
}
},
    lenthheker(event, type) {
      var data = event.target.value;

      if (type == "pinCode") {
        if (data.length != 6) {
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }

    
      if (type == "gstinVal") {
        if (data.length != 15) {
          this.gstinVal = "true";
          $("#gstinVal").html("<small>GSTIN 15 Digit Required</small>");
        }
        if (data.length == 15) {
          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
        }
      }
    },

    editField(fieldName, val) {
      this.form.field = fieldName;
      this.form.value = val;
      console.log(this.form.field);
      if (fieldName != "") {
        this.edit = "true";
      }
    },
    changheLogoSoftware(api) {
      const formData = new FormData();
      const keys = Object.keys(this.updateProfileForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.updateProfileForm[keys[n]] !== null) {
          formData.append(keys[n], this.updateProfileForm[keys[n]]);
        }
      }
      this.loading = true;
 
      this.$axios
        .post(`superadmin/${api}`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Uploaded Succesfully", {
            autoClose: 1000,
          });
        }) .finally(() => (this.loading = false))
        .catch((error) => {
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });

    },
    saveData() {
      // console.log(this.form)
      this.loading = true;
      this.$axios
        .put("superadmin/setting/1", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Updated Succesfully", {
            autoClose: 1000,
          });
          this.loadSuperAdmin();
        }).finally(() => (this.loading = false))
        .catch((error) => {
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });

      this.form.field = "";
      this.form.value = "";
      this.edit = "false";
    },
    loadSuperAdmin() {
      this.$axios
        .get("superadmin/setting", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);
          this.superAdmin = res.data;


          if (this.superAdmin.logo != "null") {
            this.proUpdatelogo  =
              this.$store.state.imgUrl +
              "/logo/" +
              this.superAdmin.id +
              "/thumbs/" +
              this.superAdmin.logo;
          }


          if (this.superAdmin.logo != "null") {
            this.proUpdateFav  =
              this.$store.state.imgUrl +
              "/favicon/" +
              this.superAdmin.id +
              "/thumbs/" +
              this.superAdmin.favicon;
          }

        });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadSuperAdmin();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.table > :not(caption) > * > * {
  /* padding-top: 3px;
    padding-bottom: 3px; */
  padding: 3px 15px 3px 15px;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
